<template>
  <div>
    <b-sidebar
      id="sidebar-right-name-project"
      ref="sidebar_site_name"
      :visible="showOpenSiteName"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-site-name', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <div
          class="b-sidebar-body mt-1"
          style="padding: 20px;"
        >
          <validation-observer ref="rulesForm">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Site Name"
                    class=""
                    style="font-weight: 700;"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Site name"
                      rules="required|max: 72"
                    >
                      <b-form-input
                        v-model="nameSite"
                        placeholder="Site Name"
                        style="font-weight: 700; border-radius: 10px; width: 100%; height: 60px;"
                        class=""
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px;"
                class="mt-2"
                variant="primary"
                @click="validationForm"
              >
                <span
                  class="align-middle"
                  style="font-weight: 700; color: #FFFFFF;"
                >Save</span>
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, max } from '@validations'
// import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenSiteName',
    event: 'update:show-open-site-name',
  },
  props: ['idsite', 'showOpenSiteName', 'rowError'],
  data() {
    return {
      required,
      max,

      selectedMove: '',
      nameSite: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
    }
  },
  watch: {
    showOpenSiteName(val) {
      this.nameSite = this.idsite.name
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
  },
  validations: {
  },
  methods: {
    submitEditar() {
      // console.log(this.idsite.id)
      if(this.idsite.id) {
        // db.collection('Sites').doc(this.idsite.id).update({
        //   name: this.nameSite,
        //   n: this.nameSite.toLowerCase(),
        // })
        // .then(() => {
          db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
            name: this.nameSite,
            n: this.nameSite.toLowerCase(),
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Site name updated',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$refs.sidebar_site_name.hide();
        // })
      }
      // db.collection('projects').doc(this.idsite.id).update({
      //     name: this.idsite.name
      // })
      // .then(() => {
      //     this.$toast({
      //         component: ToastificationContent,
      //         position: 'top-right',
      //         props: {
      //             title: `Project name updated.`,
      //             icon: 'CoffeeIcon',
      //             variant: 'success',
      //         },
      //     })
      //     this.$refs.sidebar_site_name.hide();
      // })
      // .catch(error => {
      //     console.log(error)
      // })
    },
    validationForm() {
      // console.log(this.siteProject)
      this.$refs.rulesForm.validate().then(success => {
        if (success) {
          this.submitEditar()
        }
      })
    },
    // validationForm() {
    //   this.$refs.rulesForm.validate().then(success => {
    //     if (success) {
    //       // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
    //       this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
    //     }
    //   })
    // },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-name-project {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
