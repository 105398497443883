<template>
  <div>
    <b-sidebar
      id="sidebar-right-propages"
      ref="sidebar_pro_pages"
      :visible="showOpenProPages"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-pro-pages', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <!-- <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div> -->
        <!-- <hr v-if="idsite.label"> -->
        <div
          class="b-sidebar-body mt-1"
          style="padding: 20px 20px 0 20px;"
        >
          <pro-pages :data-propages="dataPropagesSidebar ? dataPropagesSidebar : dataPropagesSidebar2" :open-side-pro="showOpenProPages" :listpro.sync="listpro" :crawls="crawls" :crawl-running="crawlRunning" :prolist="prolist" :haschanges.sync="haschanges" />
        </div>
        <b-button
          :style="infoLicense.planType === 5 && listpro.length > 5 ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 238px; height: 46px; border-radius: 23px;' : isFreePlanSite && listpro.length > 3 ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 238px; height: 46px; border-radius: 23px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px;'"
          class="mt-1 ml-2"
          variant="primary"
          v-if="idsite.permission !== 'view'"
          @click="submitEditar"
          :disabled="processSubmit || !haschanges ? true : false"
        >
          <span
            class="align-middle"
            style="font-weight: 700; color: #FFFFFF;"
          >
          {{ upgradeLicense ? 'Upgrade license and save' : upgradeLicense ? 'Upgrade license and save' : 'Save' }}
          <!-- {{ isFreePlanSite && listpro.length > 3 ? 'Upgrade license and save' : infoLicense.planType === 5 && listpro.length > 5 ? 'Upgrade license and save' : 'Save' }} -->
          </span>
        </b-button>
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import ProPages from '../sites-add/ProPages.vue'
import HelpFooter from '@/views/components/footer/HelpFooter'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import moment from 'moment'
import { auth, db, firebase, dbErrors } from '@/firebase'
import url from 'vuelidate/lib/validators/url'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ProPages,
    HelpFooter,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenProPages',
    event: 'update:show-open-pro-pages',
  },
  props: ['idsite', 'showOpenProPages', 'rowError', 'dataPropagesSidebar', 'crawlRunning', 'crawls', 'prolist'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,
      processSubmit: false,
      needUpgradePro: false,
      upgradeLicense: false,

      selectedMove: '',
      dataPropagesSidebar2: [],

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',
      listpro: [],
      haschanges: false,
      isFreePlanSite: false,
      infoLicense: '',
      planType: 2,

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
    }
  },
  watch: {
    showOpenProPages(val) {
      // console.log(val)
      setTimeout(() => {
        localStorage.removeItem('pro')
      }, 3500)

      this.needUpgradePro = false
      this.processSubmit = false
      this.upgradeLicense = false
      
      if(val) {
        this.haschanges = false
        document.body.style.overflow = 'hidden'
        this.isFreePlanSite = false
        this.infoLicense = ''

        var hostUrl = this.idsite.domain
        if(hostUrl.includes('https://') || hostUrl.includes('http://')) {
          var urlt = new URL(hostUrl)
          hostUrl = urlt.hostname
        }

        const user = auth.currentUser
        var projectid = this.$route.params.project
        if(projectid) {
          if(user) {
            db.collection('Users').doc(user.uid).get()
            .then(userDocCustomer => {
              db.collection('Sites').where('WebsiteURL', '==', hostUrl).limit(1).get()
              .then(docSite => {
                docSite.forEach(docS => {
                  db.collection('Users').doc(user.uid).collection('projects').doc(projectid).get()
                  .then(eleUserProject => {
                    var proPages = docS.data().proPages ? docS.data().proPages : []

                    db.collection('Projects').doc(projectid).collection('sites').doc(docS.id).get()
                    .then(site => {
                      // console.log(site.data());
                      if(site.data().plan === 'free' || site.data().plan === 'Free' || !site.data().plan) {
                        this.isFreePlanSite = true
                      }

                      if(site.data().plan === 'free' || site.data().plan === 'Free' || !site.data().plan) {
                          this.infoLicense = {
                            id: site.id,
                            img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                            name: site.data().name ? site.data().name : site.data().WebsiteURL,
                            domainFixed: site.data().WebsiteURL,
                            project: eleUserProject.data().name,
                            statusSite: site.data().status,
                            domain: site.data().homepage,
                            projectid: eleUserProject.data().id,
                            color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                            own: 'me',
                            invitation: '',
                            imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                            plan: 'Free',
                            permission: 'owner',
                            amount: 'free',
                            nextDue: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : '-',
                            nextDueCancel: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : '-',
                            // nextDueCancel: site.data().due ? moment(site.data().due*1000).format('MMM DD') : '-',
                            cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                            status: 'active',
                            payments: 'View Invoice',
                            customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                            customerIdSite: site.data().customerId ? site.data().customerId : '',
                            subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                            planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                            planSite: site.data().plan ? site.data().plan : '',
                            paidRecurrency: site.data().paidRecurrency ? site.data().paidRecurrency : ''
                          }
                      } else if(site.data().plan === 'basic' || site.data().plan === 'Basic' || site.data().plan === 'pro' || site.data().plan === 'Pro' || site.data().plan === 'custom' || site.data().plan === 'Custom') {
                        this.infoLicense = {
                          id: site.id,
                          img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                          name: site.data().name ? site.data().name : site.data().WebsiteURL,
                          domainFixed: site.data().WebsiteURL,
                          project: eleUserProject.data().name,
                          statusSite: site.data().status,
                          domain: site.data().homepage,
                          projectid: eleUserProject.data().id,
                          color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                          own: 'me',
                          invitation: '',
                          imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                          plan: 'Pro',
                          permission: 'owner',
                          amount: '$20.00',
                          nextDue: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY'),
                          nextDueCancel: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : '-',
                          cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                          status: 'active',
                          payments: 'View Invoice',
                          customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                          customerIdSite: site.data().customerId ? site.data().customerId : '',
                          subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                          planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                          planSite: site.data().plan ? site.data().plan : '',
                          paidRecurrency: site.data().paidRecurrency ? site.data().paidRecurrency : '',
                          pages: site.data().planType ? site.data().planType : ''
                        }
                      }

                      this.planType = site.data().planType ? parseInt(site.data().planType, 10) : 2
                      var planTypeStatus = site.data().planType ? parseInt(site.data().planType, 10) : 2

                      var counterPros = proPages.length + 1
                      if(counterPros > planTypeStatus) {
                        this.needUpgradePro = true
                      } else {
                        this.needUpgradePro = false
                      }
                    })
                  })
                })
              })
            })
          }
        }
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    haschanges(val) {
      // console.log('Has changes: ',val)
      // if(val) {
      //   this.upgradeLicense = false
      // }
    },
    listpro(val) {
      this.updatePros()
      // if(this.haschanges && this.planType) {
      //   if(val.length) {
      //     var atomArrayProUpd = []
      //     val.forEach(ele => {
      //       if(ele) {
      //         var filter = atomArrayProUpd.filter(item => item === ele)
      //         if(filter.length === 0) {
      //           atomArrayProUpd.push(ele)
      //         }
      //       }
      //     })

      //     var counterPros = atomArrayProUpd.length
      //     console.log(atomArrayProUpd, counterPros, this.planType)
      //     if(counterPros > this.planType) {
      //       this.upgradeLicense = true
      //     } else {
      //       this.upgradeLicense = false
      //     }
      //   }
      // }
      // console.log(val);
    }
  },
  validations: {
  },
  methods: {
    updatePros() {
      // setTimeout(() => {
        // if(this.haschanges && this.planType) {
        if(this.planType) {
          if(this.listpro.length) {
            var atomArrayProUpd = []
            this.listpro.forEach(ele => {
              if(ele) {
                var filter = atomArrayProUpd.filter(item => item === ele)
                if(filter.length === 0) {
                  atomArrayProUpd.push(ele)
                }
              }
            })

            var counterPros = atomArrayProUpd.length
            // console.log(atomArrayProUpd, counterPros, this.planType)
            if(counterPros > this.planType) {
              this.upgradeLicense = true
            } else {
              this.upgradeLicense = false
            }
          }
        }
      // }, 350)
    },
    submitEditar(payload) {
      // console.log(this.listpro);
      var atomArrayProUpd = []
      this.listpro.forEach(ele => {
        if(ele) {
          var filter = atomArrayProUpd.filter(item => item === ele)
          if(filter.length === 0) {
            atomArrayProUpd.push(ele)
          }
        }
      })

      var counterPros = atomArrayProUpd.length
      // console.log(atomArrayProUpd, counterPros, this.planType)
      if(counterPros > this.planType) {
        this.needUpgradePro = true
      } else {
        this.needUpgradePro = false
      }
      
      if(this.needUpgradePro) {
        // console.log('need upgrade')
        localStorage.setItem('jsonInfoLic', JSON.stringify(this.infoLicense))
        this.$refs.sidebar_pro_pages.hide()

        setTimeout(() => {
          this.processSubmit = false
        }, 6000)
      } else {
        this.processSubmit = true
        if(this.isFreePlanSite && this.listpro.length > 3) {
          // console.log('need upgrade 1')
          // console.log(this.infoLicense);
          localStorage.setItem('jsonInfoLic', JSON.stringify(this.infoLicense))
          if(this.$route.params.id) {
            localStorage.setItem('jsonUpdtPro', JSON.stringify({idsite: this.$route.params.id, listpro: this.listpro}))
          }
          this.$refs.sidebar_pro_pages.hide()

          setTimeout(() => {
            this.processSubmit = false
          }, 6000)
        } else if(this.infoLicense.planType === 5 && this.listpro.length > 5) {
          // console.log('need upgrade 2')
          // console.log(this.infoLicense);
          localStorage.setItem('jsonInfoLic', JSON.stringify(this.infoLicense))
          if(this.$route.params.id) {
            localStorage.setItem('jsonUpdtPro', JSON.stringify({idsite: this.$route.params.id, listpro: this.listpro}))
          }
          this.$refs.sidebar_pro_pages.hide()

          setTimeout(() => {
            this.processSubmit = false
          }, 6000)
        } else {
          // console.log('need upgrade 3')
          if(this.idsite.domain) {
            // var getUrl = new URL(this.idsite.domain)
            var hostUrl = this.idsite.domain
            // console.log(this.listpro)
            // this.processSubmit = false
            if(hostUrl.includes('https://') || hostUrl.includes('http://')) {
              var urlt = new URL(hostUrl)
              hostUrl = urlt.hostname
            }
            
            // console.log(hostUrl,this.dataPropagesSidebar)
            const user = auth.currentUser
            if(user) {
              db.collection('Users').doc(user.uid).get()
              .then(userDoc => {
                var userData = {}
                userData.uid = userDoc.id
                userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
                userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
                userData.status = userDoc.data().status ? userDoc.data().status : ''
                userData.email = userDoc.data().email ? userDoc.data().email : ''

                db.collection('Sites').where('WebsiteURL', '==', hostUrl).limit(1).get()
                .then(docSite => {
                  docSite.forEach(docS => {
                    var arrayProPages = docS.data().proPages ? docS.data().proPages : []
                    var arrayHProPages = docS.data().HproPages ? docS.data().HproPages : []
                    if(!arrayHProPages.length) {
                      arrayHProPages = arrayProPages
                    }

                    db.collection('Sites').doc(docS.id).update({
                      proPages: []
                    })
                    .then(() => {
                      this.listpro.forEach(ele => {
                        // if(ele.selectedInPro || ele.url === 'Homepage') {
                        if(ele) {
                          var newarrayHProPages = []
                          if(!arrayHProPages.filter(elH => elH === ele).length) {
                            arrayHProPages.push(ele)
                            newarrayHProPages = arrayHProPages

                            db.collection('Sites').doc(docS.id).update({
                              HproPages: newarrayHProPages,
                              proPages: firebase.firestore.FieldValue.arrayUnion(ele),
                              asignatedProPages: true,
                              updated: Date.now()
                            })
                          } else {
                            db.collection('Sites').doc(docS.id).update({
                              proPages: firebase.firestore.FieldValue.arrayUnion(ele),
                              asignatedProPages: true,
                              updated: Date.now()
                            })
                          }
                        }
                      })

                      setTimeout(() => {
                        db.collection('Sites').doc(docS.id).get()
                        .then(siteDoc => {
                            var proPages = siteDoc.data().proPages ? siteDoc.data().proPages : []
                            var pstatus = siteDoc.data().pSt ? siteDoc.data().pSt : []
                            var pSt = []
                            proPages.forEach(sitePro => {
                                var alreadyExistpstatus = pstatus.filter(ele => ele.url === sitePro)
                                if(alreadyExistpstatus.length) {
                                    pSt.push({
                                        url: sitePro,
                                        crD: alreadyExistpstatus[0].crD,
                                        chD: alreadyExistpstatus[0].chD
                                    })
                                } else {
                                    pSt.push({
                                        url: sitePro,
                                        crD: Date.now(),
                                        chD: null
                                    })
                                }
                            })

                            db.collection('Sites').doc(docS.id).update({
                                pSt: pSt
                            })

                            db.collection('Sites_cache').doc(docS.id).update({
                                pSt: pSt
                            })
                        })
                      }, 5000)
                      // localStorage.setItem('pro', true)
                      this.$emit('update:prolist', this.listpro)

                      dbErrors.collection('fixes').where('SiteID', '==', docS.id).get()
                      .then(docPageFixCollection => {
                        dbErrors.collection('fixes_cache').where('SiteID', '==', docS.id).limit(1).get()
                        .then(docPageFixCacheCollection => {
                          // console.log(docPageFixCollection.size,docPageFixCacheCollection.size)
                          if(docPageFixCollection.size) {
                            if(docPageFixCacheCollection.size) {
                              var arrayFixes = []
                              docPageFixCollection.forEach(docFix => {
                                if(docFix.data().changes && docFix.data().changes.length) {
                                  var filterFixesOwner = docFix.data().changes.filter(item => item.user === userDoc.id)
                                  if(filterFixesOwner.length) {
                                    // order by the last fix
                                    filterFixesOwner.sort((a, b) => b.idfix - a.idfix)
                                    filterFixesOwner.forEach(element => {
                                      var filterchangesFixesCache = arrayFixes.filter(ele => ele.id === element.id && ele.idError === element.idError && ele.url === element.url)
                                      if(filterchangesFixesCache.length === 0) {
                                        if(this.listpro.includes(element.url)) {
                                          if(element.idError === 8 || element.idError === 14 || element.idError === 3) {
                                            arrayFixes.push({
                                              id: element.id,
                                              idError: element.idError,
                                              typeError: element.typeError,
                                              old: element.old,
                                              fix: element.fix,
                                              url: element.url,
                                              urlImg: element.urlImg,
                                              date: element.date,
                                              idfix: element.idfix
                                            })
                                          } else {
                                            arrayFixes.push({
                                              id: element.id,
                                              idError: element.idError,
                                              typeError: element.typeError,
                                              old: element.old,
                                              fix: element.fix,
                                              url: element.url,
                                              date: element.date,
                                              idfix: element.idfix
                                            })
                                          }
                                        } else {
                                          if(element.idError === 1 || element.idError === 2) {
                                            dbErrors.collection('Errors').where('idpage', '==', element.url).limit(1).get()
                                            .then(docPageError => {
                                              var idDocError = ''
                                              var changesDocError = []
                                              var listErrors = []
                                              var changesPage = []
                                              docPageError.forEach(docError => {
                                                idDocError = docError.id
                                                changesDocError = docError.data().changes ? docError.data().changes : []
                                                listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                                changesPage = docError.data().changes ? docError.data().changes : []
                                              })

                                              // if(element.idError === 1) {
                                                var filterErr = listErrors.filter(ele => ele.idc === element.idError)
                                                filterErr.forEach(itemP => {
                                                  var content = itemP.cont ? itemP.cont : ''
                                                  if(content === element.fix) {
                                                    dbErrors.collection('Errors').doc(idDocError).update({
                                                      errors: firebase.firestore.FieldValue.arrayRemove(itemP)
                                                    })
                                                    .then(() => {
                                                      var contentError = element.old ? element.old : ''
                                                      itemP.iE = contentError ? false : true
                                                      itemP.cont = element.old ? element.old : ''
                                                      // ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                                      itemP.history && itemP.history.length >= 1 ? itemP.history.push({id: itemP.id, idc: itemP.idc}) : itemP.history = [{id: itemP.id, idc: itemP.idc}]
                                                      // console.log(rowErrorURL, rowError.idError, ele)
                                                      dbErrors.collection('Errors').doc(idDocError).update({
                                                        errors: firebase.firestore.FieldValue.arrayUnion(itemP)
                                                      })
                                                      .catch(e => console.log('Error add err: ', e.message))
                                                    })
                                                  }
                                                })
                                              // }
                                            })
                                          }
                                        }
                                      }
                                    })
                                  }
                                }
                              })

                              docPageFixCacheCollection.forEach(docFixCache => {
                                dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                  changes: arrayFixes,
                                  owner: userDoc.id,
                                  ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                                  updated: Date.now()
                                })
                                .then(() => {
                                  console.log('update fixes owner')
                                })
                                .catch((err) => console.log('err fixes owner', err.message))
                              })

                              var arrayFixesurl = []
                              arrayFixes.forEach(ele => {
                                if(arrayFixesurl.filter(item => item === ele.url).length === 0) {
                                  arrayFixesurl.push(ele.url)
                                }
                              })

                              arrayFixesurl.forEach(rowErrorURL => {
                                var filterURLFixesPros = arrayFixes.filter(item => item.url === rowErrorURL)
                                
                                filterURLFixesPros.forEach(rowError => {
                                  dbErrors.collection('Errors').where('idpage', '==', rowErrorURL).limit(1).get()
                                  .then(docPageError => {
                                    var idDocError = ''
                                    var changesDocError = []
                                    var listErrors = []
                                    var changesPage = []
                                    docPageError.forEach(docError => {
                                      idDocError = docError.id
                                      changesDocError = docError.data().changes ? docError.data().changes : []
                                      listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                      changesPage = docError.data().changes ? docError.data().changes : []
                                    })

                                    // update error status and cont/est
                                    var filterErr = []
                                    if(rowError.idError === 8) {
                                      // filterErr = listErrors.filter(eleErr => eleErr.id === rowError.id || (eleErr.idc === 3 && eleErr.urlS === rowErrorURL))
                                      filterErr = listErrors.filter(eleErr => eleErr.urlS === rowErrorURL && eleErr.idc === rowError.idError)
                                      // console.log(1,filterErr.length,rowErrorURL,rowError.idError)
                                      if(filterErr.length === 1) {
                                        filterErr.forEach(ele => {
                                          // console.log(ele)
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                          })
                                          .then(() => {
                                            var originalIE = ele.iE
                                            if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                              ele.iE = false
                                            } else {
                                              listErrors.filter(item => item.urlS === rowErrorURL && rowError.idError === item.idc).length >= 2 ? null : ele.iE = false
                                            }

                                            if(ele.idc === 3) {
                                              ele.est = rowError.fix
                                              ele.cont = rowError.fix
                                              ele.sta = 200
                                              ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                            } else {
                                              ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = rowError.fix : ele.cont = rowError.fix
                                              ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                              ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                            }
                                            // console.log(rowErrorURL, rowError.idError, ele)
                                            dbErrors.collection('Errors').doc(idDocError).update({
                                              errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                            })
                                            .catch(e => console.log('Error add err: ', e.message))
                                          })
                                          .catch(e => console.log('Error delete err: ', e.message))
                                        })
                                      }
                                    } else {
                                      filterErr = listErrors.filter(eleErr => eleErr.urlS === rowErrorURL && eleErr.idc === rowError.idError)
                                      // console.log(2,filterErr.length,rowErrorURL,rowError.idError)
                                      if(filterErr.length === 1) {
                                        filterErr.forEach(ele => {
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                            errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                          })
                                          .then(() => {
                                            if(rowError.idError === 8 || rowError.idError === 14 || rowError.idError === 3) {
                                              ele.iE = false
                                            } else {
                                              listErrors.filter(item => item.urlS === rowErrorURL && rowError.idError === item.idc).length >= 2 ? null : ele.iE = false
                                            }
                                            rowError.idError === 13 || rowError.idError === 15 || rowError.idError === 3 ? ele.est = rowError.fix : ele.cont = rowError.fix
                                            rowError.idError === 3 ? ele.sta = 200 : rowError.idError === 8 || rowError.idError === 14 ? ele.est = 200 : null
                                            ele.history && ele.history.length >= 1 ? ele.history.push({id: rowError.id, idc: rowError.idError}) : ele.history = [{id: rowError.id, idc: rowError.idError}]
                                            // console.log(rowErrorURL, rowError.idError, ele)
                                            dbErrors.collection('Errors').doc(idDocError).update({
                                              errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                            })
                                            .catch(e => console.log('Error add err: ', e.message))
                                          })
                                          .catch(e => console.log('Error delete err: ', e.message))
                                        })
                                      }
                                    }
                                  })
                                })
                              })

                              setTimeout(() => {
                                // console.log(docS.data().mostRecentCrawl)
                                // dbErrors.collection('Errors').where('SiteID', '==', docS.id).get()
                                dbErrors.collection('Errors').where('SiteID', '==', docS.id).where('recent_crawl_id', '==', docS.data().mostRecentCrawl).get()
                                .then(docPageError => {
                                  // console.log(docPageError.size);
                                    if(docPageError.size) {
                                        var arrayErrors = []
                                        docPageError.forEach(ele => {
                                            if(ele.data().errors && ele.data().errors.length) {
                                                ele.data().errors.forEach(item => {
                                                    arrayErrors.push(item)
                                                })
                                            }
                                        })

                                        // console.log(arrayErrors.filter(ele => ele.idc === 1 && ele.iE === true).length,arrayErrors.filter(ele => ele.idc === 2 && ele.iE === true).length);
                                        if(arrayErrors.filter(ele => ele.idc === 1 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.title) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.title': arrayErrors.filter(ele => ele.idc === 1 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 2 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.desc) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.desc': arrayErrors.filter(ele => ele.idc === 2 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 4 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.h1) {
                                            // if(arrayErrors.filter(ele => ele.idc === 4 && ele.iE === true).length <= 1) {
                                                db.collection('Sites').doc(docS.id).update({
                                                'errorsCategory.h1': arrayErrors.filter(ele => ele.idc === 4 && ele.iE === true).length
                                                })
                                            // }
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 5 && ele.iE === true).length) {
                                            // if(docS.data().errorsCategory && docS.data().errorsCategory.h2) {
                                            //   db.collection('Sites').doc(docS.id).update({
                                            //     'errorsCategory.h2': docS.data().errorsCategory.h2 - 1
                                            //   })
                                            // }
                                        } else if(arrayErrors.filter(ele => ele.idc === 8 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.Imgs) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.Imgs': arrayErrors.filter(ele => ele.idc === 8 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 6 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.wCount) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.wCount': arrayErrors.filter(ele => ele.idc === 6 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 13 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.strings) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.strings': arrayErrors.filter(ele => ele.idc === 13 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 3 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.alts) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.alts': arrayErrors.filter(ele => ele.idc === 3 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 9 && ele.iE === true).length) {
                                            // if(docS.data().errorsCategory && docS.data().errorsCategory.dLink) {
                                            //   db.collection('Sites').doc(docS.id).update({
                                            //     'errorsCategory.dLink': arrayErrors.filter(ele => ele.idc === 9 && ele.iE === true).length
                                            //   })
                                            // }
                                        } else if(arrayErrors.filter(ele => ele.idc === 14 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.missImgs) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.missImgs': arrayErrors.filter(ele => ele.idc === 14 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 11 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.urlCan) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.urlCan': arrayErrors.filter(ele => ele.idc === 11 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 10 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.gPadlock) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.gPadlock': arrayErrors.filter(ele => ele.idc === 10 && ele.iE === true).length
                                                })
                                            }
                                        } else if(arrayErrors.filter(ele => ele.idc === 15 && ele.iE === true).length) {
                                            if(docS.data().errorsCategory && docS.data().errorsCategory.susp) {
                                                db.collection('Sites').doc(docS.id).update({
                                                    'errorsCategory.susp': arrayErrors.filter(ele => ele.idc === 15 && ele.iE === true).length
                                                })
                                            }
                                        }
                                    }
                                })

                                if(userDoc.id && userDoc.data().email) {
                                  const getFirstUser = userDoc.id.slice(0, 4);
                                  const getFirstSite = this.idsite.id.slice(0, 4);
                                  var keyID = getFirstUser + getFirstSite

                                  var obj = {
                                    "author": userDoc.data().email,
                                    "css": "",
                                    "domain": docS.data().homepage.includes('https://') || docS.data().homepage.includes('http://') ? docS.data().homepage : docS.data().homepage,
                                    "key": keyID,
                                    "mode": 0
                                  }
                                  // console.log(this.idsite,obj)

                                  var config = {
                                    method: 'POST',
                                    url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                                    headers: {
                                      'Content-Type': 'application/json',
                                    },
                                    data: JSON.stringify(obj),
                                  }

                                  this.axios(config)
                                  .then(response => {
                                    console.log('Create fix file: '+response.data.status)
                                    // this.verifyFunction()
                                  })
                                  .catch(e => console.log(e.message))
                                }
                              }, 7000)
                            }
                          }
                        })
                      })

                      setTimeout(() => {
                        console.log('s pros')
                        localStorage.setItem('pro', true)
                        // this.$emit('update:prolist', this.listpro)
                        this.listpro = []
                        // db.collection('Sites').doc(docS.id).update({
                        //   // asignatedProPages: true,
                        //   updated: Date.now()
                        // })
                        // .catch(e => console.log('Error update site: ', e.message))

                        this.$refs.sidebar_pro_pages.hide()
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'SEOcloud copilot updated',
                            icon: 'BellIcon',
                            variant: 'success',
                          },
                        })
                        // this.processSubmit = false
                      }, 8000)
                    })
                  })
                  setTimeout(() => {
                    this.processSubmit = false
                  }, 8000)
                })
              })
            }
          } else {
            this.processSubmit = false
          }
        }
      }
    },
    // validationForm() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
    //       this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
    //     }
    //   })
    // },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-propages {
    width: 90%;
    max-width: 900px !important;
}
</style>
